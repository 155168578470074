.clientInfo {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}
.clientInfoLeft {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    flex-grow: 1;
}
.clientInfoRight {
    display: flex;
    flex-flow: row nowrap;
}
.menu {
    margin-top: 10px;
}
.menuItem {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    flex: 1 0 content;
}
.actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.filter {
    display: flex;
    flex-flow: row nowrap !important;
    justify-content: flex-start;
    align-items: center;
}
.filterItem {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px !important;
}
.filterItemSelect {
    min-width: 150px;
    width: min-content;
}
.operations {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;
}
.records {
    display: flex;
    flex-flow: column nowrap;
}
.record {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 10px;
    padding: 5px;
}
.recordLeft {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    flex: 1 1 auto;
}
.recordHeader {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
}
.recordInfo {
    display: flex;
    flex-flow: row wrap;
    margin-left: 10px;
}

.recordFormControl {
    display: flex !important;
    flex-flow: row nowrap !important;
    overflow: hidden; /*remove later*/
}
.recordFormGroup {
    display: flex !important;
    flex-flow: row nowrap !important;
    flex: 1 1 100% !important; /*find a better fix*/
}
.recordBefore {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 50%;
    padding: 10px;
    align-items: stretch;
}
.recordBeforeTitle {
    display: flex;
    justify-content: flex-start;
}
.recordBeforeImages {
    display: flex;
    justify-content: flex-end;
}

.recordAfter {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 50%;
    padding: 10px;
}
.recordAfterTitle {
    display: flex;
    justify-content: flex-start;
}
.recordAfterImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 100%;
}

.recordRight {
    display: flex;
    flex-flow: column nowrap;
    width: max-content;
    justify-content: center;
    align-items: center;
}
.recordSelector {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}
.recordIcons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
}
.recordCompare {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
}

.footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 10px;
}
h3,
h2 {
    margin: 0;
}

.gridList {
    flex-flow: row wrap !important;
    flex: 0 1 100% !important;
    /* // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS. */
    transform: translateZ(0);
    overflow: hidden !important;
}
.GridListTile {
    width: auto !important;
}
.titleBar {
    background-color: #0005 !important;
    height: 30px !important;
}
.tileImage {
    height: 100px;
    width: auto !important;
}

.comparisonModal {
    display: flex !important;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
}
.comparisonPaper {
    display: flex;
    flex-flow: row nowrap;
    flex-flow: 0 0 90vw;
    justify-content: center;
}
.comparisonGridImage {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}
.comparisonImage {
    height: 500px;
    /* width: 500px; */
}
.comparisonImage4 {
    height: 250px;
    width: 250px;
}

/* modal of 1 */
.m1 {
    display: flex;
    flex: 0 0 auto;
    width: 95vw;
    height: 95vh;
    min-width: auto;
    min-height: auto;
    overflow: hidden;
    justify-content: flex-end;
}
.m1cimages {
    display: flex;
    flex-flow: row wrap;
    flex: 1 0 0;
    justify-content: flex-start;
    overflow: hidden;
}
.m2imagenode {
    width: 50%;
    height: 100%;
}
.m3imagenode {
    width: 50%;
    height: 50%;
}
.m1clabel {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.m1right {
    display: flex;
    flex: 0 0 auto;
    flex-flow: column nowrap;
    align-items: stretch;
    background-color: rgb(250, 250, 250);
}
.m1rtop {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 0px 15px;
}
.m1rbottom {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 0 15px;
    overflow: scroll;
}

.modalTitle {
    font-size: 21px;
    font-weight: bold;
    margin-right: 10px;
}
.modalSectionTitle {
    font-size: 16px;
    font-weight: bold;
}
.m3clabel {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.m1rbuttons {
    display: flex;
    padding: 0px 15px 15px 15px;
    align-items: center;
    justify-content: space-between;
}
.navigationButtons {
    margin-right: 4px !important;
}

#note-form .jodit-workplace > div:first-child::-webkit-scrollbar {
    width: 6px;
}
#note-form .jodit-workplace > div:first-child::-webkit-scrollbar-thumb {
    background: #c1c1c199;
    border-radius: 2px;
}
