.Calendar-container {
    flex-grow: 1;
    transition: margin 140ms cubic-bezier(0.11, 0.84, 0.49, 0.88) 0ms;
}

/*Calendar nav*/
.Calendar-nav-drawer-margin {
    width: calc(100% - 240px - 45px) !important;
    margin-left: 240px !important;
    margin-right: 45px !important;
}

.Calendar-nav-margin {
    width: calc(100% - 45px) !important;
    margin-right: 45px !important;
}

.Calendar-nav {
    display: flex;
    transition: margin 140ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

/* Rewritten default style to match design */
.MuiBadge-anchorOriginTopRightRectangle {
    top: -0.2rem !important;
}

.MuiBadge-colorSecondary {
    background-color: rgb(220, 0, 78) !important;
    font-size: 0.65rem !important;
}

.textfield-with-placeholder input::placeholder,
.textfield-with-placeholder input::-webkit-input-placeholder,
.textfield-with-placeholder input:-ms-input-placeholder {
    font-size: 14px !important;
}
