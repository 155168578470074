/* Temporary fix for scrollbar */

/* Chrome browser */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .calendar-appointments-container-scroll-fix {
        margin-right: 72px !important;
        overflow-y: scroll !important;
    }
}

/* Firefox */
@-moz-document url-prefix() {
    .calendar-appointments-container-scroll-fix {
        margin-right: 80px !important;
        overflow-y: auto !important;
    }
}
